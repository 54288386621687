import Vue from 'vue'
import App from './App.vue'
import store from "./store"
import Vuex from 'vuex'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import "./assets/css/reset.css"
import * as echarts from 'echarts';
import 'echarts-gl'
import '@/icons' // icon
import './utils/drag.js'
import CustomDialog from './components/CustomDialog/CustomDialog'
import VueParticles from 'vue-particles'  
Vue.use(VueParticles)  
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import animated from 'animate.css'
Vue.use(animated)
import './assets/fonts/font.css';
// import VueI18n from 'vue-i18n'
// import {cn } from './assets/lang/cn.js'
// import {en}  from './assets/lang/en.js'
// Vue.use(VueI18n)
// const i18n = new VueI18n({
// locale: 'en',   // 设置语言
// messages: {
//     cn: { ...cn },
//   en: { ...en },
//  }
// });
import qs from 'qs'
Vue.prototype.$qs = qs
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI,Vuex);
Vue.component('CustomDialog', CustomDialog)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
