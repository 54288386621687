<template>
  <modal
    v-loading="dialog.loading"
    ref="modal"
    :name="dialog.name"
    :draggable="'.modal-header' || true"
    :adaptive="true"
    :scrollable="dialog.height ? false : true"
    :min-width="dialog.minWidth"
    :min-height="dialog.minHeight"
    :delay="100"
    :click-to-close="dialog.clickToClose"
    :width="dialog.width"
    :height="dialog.height || 'auto'"
    :resizable="dialog.resizable"
    transition="nice-modal-fade"
    class="modal"
  >
    <div v-if="dialog.showHeader" class="modal-header">
      <div class="modal-header-title" v-html="dialog.title"/>
      <div v-if="dialog.showHeaderHandle" class="modal-header-control">
        <div class="modal-header-control-screen">
          <div
            v-if="dialog.fullScreen"
            class="el-icon-rank handle-icon hover:color-blue-500 "
            @click="exit"/>
          <div
            v-else
            class="el-icon-full-screen handle-icon hover:color-blue-500"
            @click="full"/>
        </div>
        <div
          class="modal-header-control-close el-icon-close handle-icon hover:color-blue-500 hover:rotate-180"
          @click="close"/>
      </div>
    </div>
    <div class="modal-body">
      <slot/>
    </div>
    <template v-if="dialog.showFooter">
      <div class="modal-footer text-center">
        <el-button v-show="dialog.showCancelBtn" plain @click="close">取消</el-button>
        <el-button :loading="dialog.loading" type="primary" @click="confirm">{{
            dialog.confirmText || '确认'
          }}
        </el-button>
      </div>
    </template>
    <template v-else>
      <slot name="footer"/>
    </template>
  </modal>
</template>
<script>
export default {
  name: 'CustomDialog',
  props: {
    dragDialog: {
      type: Object,
      required: false,
      default: () => {
      }
    }
  },
  data() {
    return {
      dialog: {},
      defaultDialog: {
        title: '',
        name: 'modal',
        dialog: true,
        dynamic: true,
        showFooter: false,
        showHeader: true,
        showHeaderHandle: true,
        showCancelBtn: true,
        loading: false,
        clickToClose: false,
        fullScreen: false,
        visible: false,
        resizable: true,
        // color: true,
        pivotX: 0.5,
        pivotY: 0.5,
        width: 50,
        height: 50,
        reset: true,
        minWidth: 300,
        minHeight: 300,
        maxHeight: 100,
        classes: ['v--modal-overlay']
      }
    }
  },
  watch: {
    dragDialog: {
      handler(props) {

        this.dialog = {
          ...this.defaultDialog,
          ...props
        }
        if (this.dialog.fullScreen) {
          this.$nextTick(() => {
            this.full()
          })
        } else {
          this.$nextTick(() => {
            this.exit()
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    full() {
      this.$children[0].modal = {
        ...this.$children[0].modal,
        width: 100,
        height: 100,
        widthType: '%',
        heightType: '%'
      }
      this.dialog = {
        ...this.dialog,
        fullScreen: true
      }
    },
    exit() {
      const { width, height } = this.dialog

      this.$children[0].modal = {
        ...this.$children[0].modal,
        width,
        height,
        heightType: Number(height) > 100 ? 'px' : '%',
        widthType: Number(width) > 100 ? 'px' : '%'
      }

      this.dialog = {
        ...this.dialog,
        fullScreen: false
      }
    },
    close() {
      this.$modal.hide(this.dialog.name)
      this.dialog = {
        ...this.dialog,
        loading: false
      }
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm', this.dialog.name)
    }
  }
}
</script>

<style scoped lang="less">
.modal {
  z-index: 1200;

  &-header {
    height: 40px;
    background: #f2f6ff;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: move;
    border-bottom: solid #f7fafc;

    &-title {

    }

    &-control {
      position: absolute;
      right: 1rem;
      display: flex;
      align-items: center;

      .handle-icon {
        display: inline-block;
        color: #909399;
        speak: none;
        font-style: normal;
        font-weight: 400;
        margin: 6px;
        font-variant: normal;
        text-transform: none;
        vertical-align: baseline;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        cursor: pointer;
      }
    }

  }

  &-body {
    padding: 2px 1rem 0;
    overflow: auto;
    height: 100%;
  }

  /* &-footer {
    float:right;
    position: absolute;
    bottom: 0px;
    background: #fff;
    z-index: 10;
    width: 100%;
    border-top: 1px solid #f7fafc;
    height: 60px;
    line-height: 60px;
    min-height: 60px;
  } */

  /deep/ .vue-modal-resizer {
    z-index: 10;
  }
}
</style>
