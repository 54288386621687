<template>
  <div class="homeAll">
    <!-- <img src="../assets/img/index/4.webp" alt="" class="homeAllFirstImgs" /> -->
    <div class="homeAllSecond">
      <router-link to="/pc/indexs">御之安科技有限公司</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.homeAll {
  width:100%;
  height:100%;
  position:fixed;
  background: url(../assets/img/index/4.webp) no-repeat;
  background-size:100% 100%;
  display: flex;
  flex-direction: column;
  .homeAllFirstImgs {
    width: 100%;
    // position: fixed;
    // left: 0px;
    // top: 0px;
    // right: 0px;
    // bottom: 0px;
    // z-index: -999;
  }
  .homeAllSecond {
    width: 100%;
    margin:auto;
    display: flex;
    justify-content: center;
    // background-image: url(../assets/img/aboutus/3.png);
    // background-size: 400px 100%;
    // background-repeat: no-repeat;
    // background-position: center center;
    padding: 120px;
    a{
      color: #fff;
      font-size: 32px;
      
    }
  }
}
</style>