import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Home"
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: "/",
  //   name: "login",
  //   component: Login,
  // },
  {
    path: "/",
    name: "index",
    meta: { title: "首页" },
    component: () => import("@/views/models/PC/indexs/index"), //懒加载 
  },
  {
    path: "/pc",
    name: "index",
    meta: { title: "导航页" },
    component: () => import("@/views/index"), //懒加载 
    children: [

      {
        path: "/pc/mappingCenter",
        name: "mappingCenter",
        meta: { title: "网络资产测绘平台" },
        component: () => import("@/views/models/PC/productCenter/mappingCenter"), //懒加载 
      },
      {
        path: "/pc/ctfCenter",
        name: "ctfCenter",
        meta: { title: "ctf竞赛平台" },
        component: () => import("@/views/models/PC/productCenter/ctfCenter"), //懒加载 
      },
      {
        path: "/pc/redBlueCenter",
        name: "redBlueCenter",
        meta: { title: "红蓝对抗平台" },
        component: () => import("@/views/models/PC/productCenter/redBlueCenter"), //懒加载 
      },
      {
        path: "/pc/vulnerabilityCenter",
        name: "vulnerabilityCenter",
        meta: { title: "漏洞管理平台" },
        component: () => import("@/views/models/PC/productCenter/vulnerabilityCenter"), //懒加载 
      },
      {
        path: "/pc/fencingOperations",
        name: "fencingOperations",
        meta: { title: "护网行动" },
        component: () => import("@/views/models/PC/Securityservice/fencingOperations"), //懒加载 
      },
      {
        path: "/pc/safetyTraining",
        name: "safetyTraining",
        meta: { title: "安全培训" },
        component: () => import("@/views/models/PC/Securityservice/safetyTraining"), //懒加载 
      },
      {
        path: "/pc/safetyCompetition",
        name: "safetyCompetition",
        meta: { title: "安全竞赛" },
        component: () => import("@/views/models/PC/Securityservice/safetyCompetition"), //懒加载 
      },
      {
        path: "/pc/codeAudit",
        name: "codeAudit",
        meta: { title: "代码审计" },
        component: () => import("@/views/models/PC/Securityservice/codeAudit"), //懒加载 
      },
      {
        path: "/pc/emergencyResponse",
        name: "emergencyResponse",
        meta: { title: "应急响应" },
        component: () => import("@/views/models/PC/Securityservice/emergencyResponse"), //懒加载 
      },
      {
        path: "/pc/securityOperation",
        name: "securityOperation",
        meta: { title: "安全运维" },
        component: () => import("@/views/models/PC/Securityservice/securityOperation"), //懒加载 
      },
      {
        path: "/pc/securityReinforcement",
        name: "securityReinforcement",
        meta: { title: "安全加固" },
        component: () => import("@/views/models/PC/Securityservice/securityReinforcement"), //懒加载 
      },
      {
        path: "/pc/securityAdvisory",
        name: "securityAdvisory",
        meta: { title: "安全咨询" },
        component: () => import("@/views/models/PC/Securityservice/securityAdvisory"), //懒加载 
      },
      {
        path: "/pc/platformEquipment",
        name: "platformEquipment",
        meta: { title: "平台设备" },
        component: () => import("@/views/models/PC/school/platformEquipment"), //懒加载 
      },
      {
        path: "/pc/aboutUs",
        name: "aboutUs",
        meta: { title: "关于我们" },
        component: () => import("@/views/models/PC/aboutYzn/aboutUs"), //懒加载 
      },
      {
        path: "/pc/companyProfile",
        name: "companyProfile",
        meta: { title: "公司介绍" },
        component: () => import("@/views/models/PC/aboutYzn/companyProfile"), //懒加载 
      },
      {
        path: "/pc/joinUs",
        name: "joinUs",
        meta: { title: "加入我们" },
        component: () => import("@/views/models/PC/aboutYzn/joinUs"), //懒加载 
      },
      {
        path: "/pc/contactUs",
        name: "contactUs",
        meta: { title: "联系我们" },
        component: () => import("@/views/models/PC/aboutYzn/contactUs"), //懒加载 
      },
      {
        path: "/pc/legalStatement",
        name: "legalStatement",
        meta: { title: "法律声明" },
        component: () => import("@/views/models/PC/legalStatement"), //懒加载 
      },
      {
        path: "/pc/intellectualPropertyDescription",
        name: "intellectualPropertyDescription",
        meta: { title: "知识产权说明" },
        component: () => import("@/views/models/PC/intellectualPropertyDescription"), //懒加载 
      },
      {
        path: "/pc/privacyProtection",
        name: "privacyProtection",
        meta: { title: "隐私保护" },
        component: () => import("@/views/models/PC/privacyProtection"), //懒加载 
      },
    ]
  },
  {
    path: "/mb",
    name: "index1",
    meta: { title: "导航页" },
    component: () => import('@/views/models/Mb/index.vue'), //懒加载 
    children: [
      {
        path: "/mb/index",
        name: "mbindex",
        meta: { title: "移动端首页1" },
        component: () => import("@/views/models/Mb/indexs/index"), //懒加载 
      },
      {
        path: "/mb/mappingCenter",
        name: "mappingCenter1",
        meta: { title: "网络资产测绘平台1" },
        component: () => import("@/views/models/Mb/productCenter/mappingCenter"), //懒加载 
      },
      {
        path: "/mb/ctfCenter",
        name: "ctfCenter1",
        meta: { title: "ctf竞赛平台1" },
        component: () => import("@/views/models/Mb/productCenter/ctfCenter"), //懒加载 
      },
      {
        path: "/mb/redBlueCenter",
        name: "redBlueCenter1",
        meta: { title: "红蓝对抗平台1" },
        component: () => import("@/views/models/Mb/productCenter/redBlueCenter"), //懒加载 
      },
      {
        path: "/mb/vulnerabilityCenter",
        name: "vulnerabilityCenter1",
        meta: { title: "漏洞管理平台1" },
        component: () => import("@/views/models/Mb/productCenter/vulnerabilityCenter"), //懒加载 
      },
      {
        path: "/mb/fencingOperations",
        name: "fencingOperations1",
        meta: { title: "护网行动1" },
        component: () => import("@/views/models/Mb/Securityservice/fencingOperations"), //懒加载 
      },
      {
        path: "/mb/safetyTraining",
        name: "safetyTraining1",
        meta: { title: "安全培训1" },
        component: () => import("@/views/models/Mb/Securityservice/safetyTraining"), //懒加载 
      },
      {
        path: "/mb/safetyCompetition",
        name: "safetyCompetition1",
        meta: { title: "安全竞赛1" },
        component: () => import("@/views/models/Mb/Securityservice/safetyCompetition"), //懒加载 
      },
      {
        path: "/mb/codeAudit",
        name: "codeAudit1",
        meta: { title: "代码审计1" },
        component: () => import("@/views/models/Mb/Securityservice/codeAudit"), //懒加载 
      },
      {
        path: "/mb/emergencyResponse",
        name: "emergencyResponse1",
        meta: { title: "应急响应1" },
        component: () => import("@/views/models/Mb/Securityservice/emergencyResponse"), //懒加载 
      },
      {
        path: "/mb/securityOperation",
        name: "securityOperation1",
        meta: { title: "安全运维1" },
        component: () => import("@/views/models/Mb/Securityservice/securityOperation"), //懒加载 
      },
      {
        path: "/mb/securityReinforcement",
        name: "securityReinforcement1",
        meta: { title: "安全加固1" },
        component: () => import("@/views/models/Mb/Securityservice/securityReinforcement"), //懒加载 
      },
      {
        path: "/mb/securityAdvisory",
        name: "securityAdvisory1",
        meta: { title: "安全咨询1" },
        component: () => import("@/views/models/Mb/Securityservice/securityAdvisory"), //懒加载 
      },
      {
        path: "/mb/platformEquipment",
        name: "platformEquipment1",
        meta: { title: "平台设备1" },
        component: () => import("@/views/models/Mb/school/platformEquipment"), //懒加载 
      },
      {
        path: "/mb/aboutUs",
        name: "aboutUs1",
        meta: { title: "关于我们1" },
        component: () => import("@/views/models/Mb/aboutYzn/aboutUs"), //懒加载 
      },
      {
        path: "/mb/companyProfile",
        name: "companyProfile1",
        meta: { title: "公司介绍1" },
        component: () => import("@/views/models/Mb/aboutYzn/companyProfile"), //懒加载 
      },
      {
        path: "/mb/joinUs",
        name: "joinUs1",
        meta: { title: "加入我们1" },
        component: () => import("@/views/models/Mb/aboutYzn/joinUs"), //懒加载 
      },
      {
        path: "/mb/contactUs",
        name: "contactUs1",
        meta: { title: "联系我们1" },
        component: () => import("@/views/models/Mb/aboutYzn/contactUs"), //懒加载 
      },
      {
        path: "/mb/legalStatement",
        name: "legalStatement1",
        meta: { title: "法律声明1" },
        component: () => import("@/views/models/Mb/legalStatement"), //懒加载 
      },
      {
        path: "/mb/intellectualPropertyDescription",
        name: "intellectualPropertyDescription1",
        meta: { title: "知识产权说明1" },
        component: () => import("@/views/models/Mb/intellectualPropertyDescription"), //懒加载 
      },
      {
        path: "/mb/privacyProtection",
        name: "privacyProtection1",
        meta: { title: "隐私保护1" },
        component: () => import("@/views/models/Mb/privacyProtection"), //懒加载 
      },
    ]
  },
  // {
  //   path: "/",
  //   name: "index",
  //   component: () => import('@/views/index.vue'),

  // },
  // {
  //   path: '/Mb',//移动端
  //   name: 'Mb',
  //   component: () => import('@/views/models/Mb/index.vue')
  // },

  //404
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue")
  }
]

const router = new VueRouter({
  routes
})
//保卫
// router.beforeEach((to, from, next) => {
//   if (to.path === "/") {
//     next()//登录页面通过
//   } else {
//     let token = localStorage.token
//     checkToken({
//       token
//     }).then(res => {
//       // console.log(res.data)
//       if (res.code == 200) { 
//         next()
//       } else {

//         next("/")
//       }
//     })
//   }
// })
//在 router/index.js 增加
router.beforeEach((to, from, next) => {
  if (to.path == '/') {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      router.push({ path: '/mb/index', });
    }
  }
  next();
});
export default router