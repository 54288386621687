<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
data(){
  return{}
},
mounted(){
  // 判断进入的是那个端 跳入相应的路由 页面
     if (this._isMobile()) {
       //移动端
      this.$router.push('/mb/index');
    } 
},
methods:{
  //判断 是否进入的是移动端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
}
}
</script>
<style lang="less">


</style>
